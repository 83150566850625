.reference-item {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
  overflow: hidden;
  .image-wrap {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    height: 220px;
    display: block;

    img {
      @include objetfit;
      transition: $transition-base;
    }
  }
  .content-wrap {
    padding: 24px;
    flex: 1 0 auto;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    @include absolutefull;
    text-decoration: none;
    color: $white;
    z-index: 1;
    i {
      font-size: toRem(24);
      margin-right: 4px;
    }
    .town {
      font-size: toRem(22);
      font-weight: 500;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
    }
    .vykon {
      display: flex;
      align-items: center;
    }
    &::before {
      content: '';
      @include absolutefull;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
      z-index: -1;
    }
  }
  &:hover {
    .image-wrap {
      img {
        transform: scale(1.1);
      }
    }
  }
  @include media-breakpoint-up(lg) { 
    height: calc(100% - 24px);
    
  }
}