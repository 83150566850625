/* @import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/forms"; */

/* @import './../base/variables';
@import './../helpers/mixins';
 */

.form-group {
    margin-bottom: 16px;
}
.form-check-label {
    cursor: pointer;
    color: $secondary;
}
.form-check-input:checked {

}

