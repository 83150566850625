.breadcrumbs {
  margin-bottom: 0;
  padding: 8px 0 16px;
  font-size: toRem(12);
  color: $gray-700;
  letter-spacing: 0.5px;
  span {
    span {
      display: none;
      &:nth-last-of-type(2) {
        display: inline;
      }
    }
    i {
      display: none;
      &:nth-last-of-type(2) {
        display: inline-block;
        transform: rotate(180deg);
      }
    }
  }
  a {
    text-decoration: none;
    color: $gray-700;
    &:hover {
      color: $primary;
    }
  }
  .breadcrumb_last {
    font-weight: 500;
  }
  @include media-breakpoint-up(lg) { 
    padding: 16px 0;
    span {
      i {
        display: inline-block;
        &:nth-last-of-type(2) {
          display: inline-block;
          transform: none;
        }
      }
      span {
        display: inline-block;
      }
    }
  }
}