h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  color: $black;

}

@mixin body {
  color: $text;
  font-size: 1.6rem;
  line-height: 24px;
  /* @include media-breakpoint-up(lg) { 
    font-size: 2rem;
    line-height: 35px;
  } */
}

@mixin h1 {
  font-size: toRem(32);
  margin: 0;
  font-family: 'Poppins';
  font-weight: bold;
  //font-family: 'Avaline Script';
  @include media-breakpoint-up(md) { 
    font-size: toRem(36);
  }
  @include media-breakpoint-up(lg) { 
    font-size: toRem(48);
    line-height: 60px;
  }
  /* @include media-breakpoint-up(xxl) { 
    font-size: 7.2rem;
    line-height: 99px;
  } */
}

@mixin h2 {
  font-size: toRem(24);
  font-family: 'Poppins';
  font-weight: bold;
  margin-bottom: 24px;
  @include media-breakpoint-up(md) { 
    font-size: toRem(34);
  }
  @include media-breakpoint-up(lg) { 
    font-size: toRem(36);
  }
}

@mixin h3 {
  font-size: toRem(20);
  font-family: 'Poppins';
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;
  @include media-breakpoint-up(lg) { 
    font-size: toRem(24);
  }
}

@mixin h4 {
  font-size: toRem(18);
  font-family: 'Poppins';
  margin: 0;
  margin-bottom: 0;
  font-weight: bold;
  @include media-breakpoint-up(lg) { 
    font-size: toRem(18);
  }
}

@mixin h5 {
  font-size: toRem(18);
  font-family: 'Poppins';
  margin: 0;
  margin-bottom: 0;
  font-weight: bold;
  @include media-breakpoint-up(lg) { 
    font-size: toRem(18);
  }
}

@mixin h6 {
  font-size: toRem(18);
  font-family: 'Poppins';
  margin: 0;
  margin-bottom: 0;
  font-weight: bold;
  font-family: 'Poppins';
  @include media-breakpoint-up(lg) { 
    font-size: toRem(18);
  }
}



/* body {
  @include body;
}
 */
h1, .h1 {
  @include h1;
}

h2, .h2 {
  @include h2;
  span {
    color: $secondary
  }
}

h3, .h3 {
  @include h3;
}

h4, .h4 {
  @include h4;
}

h5, .h5 {
  @include h5;
}
h6, .h6 {
  @include h6;
}


/* p, ul {
  @include body;
  margin-bottom: 20px;
}

table {
  color: $text;
}

td, th {
  @include body;
}

a {
  @include body;
}

small {
  font-size: 1.2rem;
} */