.swal2-popup {
  border-radius: 4px !important;
  .swal2-close {
    border: none;
    outline: none;
    background: transparent;
    font-size: toRem(24);
    color: $gray-700;
    position: absolute;
    right: 32px;
    top: 28px;
    &:focus {
      box-shadow: none;
    }
  }
}
.swal2-html-container {
  margin: 0 !important;
  text-align: left !important;
}
.modal-content {
  border-radius: 0;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  border: none;
  .modal-header {
    border: none;
    padding: 32px 32px 16px;
    h5 {
      font-size: toRem(18);
    }
    .close {
      border: none;
      outline: none;
      background: transparent;
      font-size: toRem(24);
    }
  }
  .modal-body {
    padding: 0 32px;
  }
  .modal-footer {
    border: none;
    padding: 16px 32px 32px;
  }
}