@import "~bootstrap/scss/forms/form-check";
.footer-assets {
  display: flex;
  flex-wrap: wrap;
  background-color: $secondary;
}

.footer-content {
  background-color: $black;
  padding-top: 70px;
}
.footer-header {
  margin-bottom: 38px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .socials-wrap {
    display: flex;
    align-items: center;
    padding: 32px 0 10px;
    a {
      font-size: toRem(32);
      color: $white;
      text-decoration: none;
      margin: 0 12px;
      &:hover {
        color: $secondary;
      }
    }
  }
  @include media-breakpoint-up(lg) { 
    padding: 0;
    flex-direction: row;
    margin-bottom: 50px;
    .socials-wrap {
      padding: 0;
      a {
        font-size: toRem(24);
        margin: 0 8px;
      }
    }
  }
}
.footer-collumn {
  margin-bottom: 30px;
  padding: 0 50px;
  text-align: center;
  
  h4 {
    font-size: toRem(18);
    color: $white;
    margin-bottom: 16px;
    font-family: $font-family-sans-serif;
  }
  .store-informations {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nav-item {
    .nav-link {
      font-size: toRem(16);
      letter-spacing: 0.25px;
      color: $white;
      padding: 0;
      margin-bottom: 16px;
      &:hover {
        color: $secondary;
      }
    }
  }
  a, p {
    font-size: toRem(16);
    letter-spacing: 0.25px;
    color: $white;
    padding: 0;
    line-height: 24px;
    
  }
  p {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    i {
      font-size: toRem(16);
      margin-right: 6px;
    }
  }
  a {
    text-decoration: none;
    color: $gray-200;
    &:hover {
      color: $secondary;
    }
    &.map {
      color: $primary;
      text-decoration: underline;
    }
  }

  .bolder {
    font-weight: 500;
    display: block;
  }
  @include media-breakpoint-up(lg) { 
    padding: 0 0;
    text-align: left;
    .store-informations {
      align-items: flex-start;
    }
  }
}
.footer-bottom {
  padding: 16px 50px;
  border-top: 1px solid $gray;
  p {
    margin-bottom: 0;
    color: $white;
    font-size: toRem(12);
    text-align: center;
    a {
      color: $white;
    }
  }
}


.footer-contact {
  padding: 64px 0;
  background-color: $black;
  color: $white;
  position: relative;
  z-index: 1;
  .contact-content {
    max-width: 550px;
    margin-bottom: 64px;
    position: relative;
    &::before {
      content: '';
      @include absolutefull;
      top: -64px;
      bottom: -64px;
      left: -15px;
      right: -15px;
      background-image: url('./../assets/img/contact-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: -1;
    }
  }
  h2,p, h4 {
    color: $white;
  }
  p {
    font-size: toRem(18);
  }
  h2 {
    span {
      color: $secondary;
    }
  }
  .contact-item {
    border: 1px solid #545454;
    border-radius: 30px;
    padding: 24px 19px;
    display: flex;
    margin-top: 48px;
    .image-wrap {
      width: 68px;
      height: 68px;
      flex: 0 0 68px;
      border-radius: 50%;
      margin-right: 24px;
      overflow: hidden;
      img {
        @include objetfitcontain;
      }
    }
    .contact-data {
      .name {
        color: $secondary;
        font-weight: 500;
      }
      .description {
        font-size: toRem(14);
        margin-bottom: 8px;
      }
      .contact-phone {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $white;
        i {
          margin-right: 6px;
        }
        span {
          color: $gray-200;
          font-size: toRem(14);
        }
      }
    }

  }
  .contact-form-wrap {
    margin-top: 64px;
    .radiobuttons-wrap {
      display: flex;
      overflow: auto;
      flex-wrap: wrap;
      .form-radio {
        position: relative;
        margin-right: 8px;
        margin-bottom: 8px;
        .form-radio-input {
          width: 1px;
          height: 1px;
          opacity: 0;
          visibility: hidden;
          position: absolute;
          &:checked {
            & ~ .form-radio-label {
              background-color: $secondary;
              border-color: $secondary;
            }
          }
        }
        .form-radio-label {
          color: $white;
          font-size: toRem(12);
          padding: 10px 12px;
          border: 1px solid #545454;
          border-radius: 30px;
          cursor: pointer;
          white-space: nowrap;
          &:hover {
            border-color: $secondary;
          }
        }
      }
    }
    .form-control  {
      background-color: transparent;
      color: #A4A4A4;
      //border: none;
      border-width: 1px;
      
      border-radius: 0;
      &.is-invalid {
        border-radius: 10px;
      }
      &:not(.is-invalid) {
        border-color: transparent;
        border-bottom: 1px solid #545454;
        &:focus {
          border-bottom-color: $secondary;
        }
      }
    }
    .form-label {
      color: #A4A4A4;
      font-weight: 400;
      margin-bottom: 0;
    }
    .form-check {
      padding-left: 40px;
      .form-check-input[type=checkbox] {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin-left: -40px;
        cursor: pointer;
        &:not(.is-invalid) {
          & ~ .form-check-label {
            color: $white;
            a {
              color: $white;
            } 
          }
        }
        &:checked {
          background-color : $green-500;
          border-color: $green-500;
        }
      }  
    }
    
    .form-check-label {
      
      font-size: toRem(14);
      a {
        
        text-decoration: underline;
        font-size: toRem(14);
      }
    }
    .button-wrap {

      .btn {
        width: 100%;
        padding: 11px 40px;
      }
    }
    .error-response {
      color: $red;
      display: none;
    }
    .success-response {
      color: $secondary;
      display: none;
    }
  }
  @include media-breakpoint-up(sm) {
    .contact-content {
      &::before {
        left: calc((((100vw - #{get-breakpoints("sm")})/2) + ($grid-gutter-width / 2))* -1 );
        right: calc((((100vw - #{get-breakpoints("sm")})/2) + ($grid-gutter-width / 2))* -1 );
      }
    }
  }
  @include media-breakpoint-up(md) { 
    &::before {
      content: '';
      @include absolutefull;
      background-image: url('./../assets/img/contact-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: -1;
      right: 50%;
    }
    .contact-content {
      margin-bottom: 0;
      &::before {
        display: none;
      }
    }
    .contact-form-wrap {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) { 
    padding: 96px 0;
    .contact-item {
      padding: 32px;
      width: fit-content;
    }
    
    .contact-form-wrap {
      padding: 0 15px;
      .button-wrap {
        text-align: right;
        .btn {
          width: auto;
        }
      }
    }
  }
}