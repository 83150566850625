.mobile-menu-wrap {
  background-color: $primary;
  position: fixed;
  top: 77px;
  left: 0;
  bottom: 0;
  width: 100%;
  //max-width: 600px;
  z-index: 21;
  padding: 24px 20px 24px;
  overflow-y: auto;
  transition: all 0.3s;
  transform: translateX(-100%);
  display: flex;
  flex-direction: column;
  &.show {
    transform: none;
  }
  .navbar-1, .navbar-2 {
    padding: 12px 0;
    border-bottom: 1px solid $gray-800;
  }
  .navbar-nav {
    text-align: center;
    .nav-item  {
      display: flex;
      //align-items: center;
      flex-wrap: wrap;
      padding: 20px 0;
      justify-content: center;
    }
    .nav-link {
      color: $white;
      font-weight: 400;
      font-size: toRem(24);
      padding: 0;
    }
    .has-children {
      position: relative;
      align-items: center;
      padding-bottom: 0;
      .show-submenu {
        //position: absolute;
        //padding: 15px 0;
        //right: 5px;
        margin-left: 6px;
        font-size: toRem(20);
        &::after {
          content: '\e917'; 
          font-family: 'icomoon';
          color: $white;
        }
        &.active {
          &::after {
            content: '\e918';
          }
        }
      }
    }
    
    .sub-menu {
      display: none;
      list-style: none;
      padding-left: 0;
      flex: 0 0 100%;
      //padding-top: 12px;
      
      .sub-menu {
       padding-left: 12px; 
      }
      .nav-item {
        border-bottom: none;
      }
      .nav-link {
        &.energia {
          padding-left: 44px;
          background-image: url('./../assets/img/leaf.png');
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: 10px 4px;
        }
        &.energetika {
          padding-left: 44px;
          background-image: url('./../assets/img/union.png');
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: 10px 4px;
        }
      }
    }
    .active {
      > .nav-link {
          font-weight: 500;
          color: $secondary;
          &.energetika {
            color: $orange;
          }
      }
    }
  }
  .buttons-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn {
      margin-bottom: 20px;
    }
  }
  .contact-info-wrap {
    text-align: center;
    margin-top: 32px;
    p {
      margin-bottom: 32px;
      i {
        font-size: toRem(24);
        margin-right: 16px; 
        color: $white;
      }
      a {
        color: $white;
        font-size: toRem(18);
        text-decoration: none;
      }
    }
  }
  .social-ikons {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    flex-direction: column;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      //padding: 10px;
      color: $white;
      margin: 0 15px;
      text-decoration: none;
      i {
        font-size: toRem(36);
      }
    }
  }
  @include media-breakpoint-up(lg) { 
    display: none;
  }
}