.page-header {
  
  position: relative;
  z-index: 1;
  .header-content {
    padding: 120px 0 95px;
  }
  .image-wrap {
    @include absolutefull;
    z-index: -1;
    border-radius: 0px 0px 30px 30px;
    overflow: hidden;
    &:after {
      content: '';
      @include absolutefull;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    }
    img {
      @include objetfit;
    }
  }
  h1 {
    color: $white;
    margin-bottom: 24px;
    line-height: 2;
  }
  p {
    font-size: toRem(18);
    color: $white;
    margin-bottom: 0;
    line-height: 20px;
  }
  .attributtes {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    * {
      margin: 4px;
    }
    .date {
      font-size: toRem(18);
      color: $white;
      display: inline-block;
    }
    .dot {
      color: $white;
      font-size: toRem(26);
      line-height: 1;
    }
    a.category {
      text-decoration: none;
      font-size: toRem(18);
      color: $white;
      &:hover {
        color: $secondary;
      }
    }
  }
  @include media-breakpoint-up(md) { 
    .header-content {
      padding: 152px 0 64px;
    }
    h1 {
      margin-bottom: 24px;
      line-height: 72px;
    }
  }
  @include media-breakpoint-up(lg) { 
    //position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0;
    
  }
}

.home-header {
  padding-top: 120px;
  padding-bottom: 24px;
  @include media-breakpoint-up(md) { 
    padding-top: 152px;
    h1 {
      line-height: 72px;
    }
  }
}