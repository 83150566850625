.woocommerce-pagination {
  margin-top: 24px;
  margin-bottom: 64px;
  .page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    li {
      margin: 8px;
    }
    a.page-numbers, span.dots {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid $gray-300;
      border-radius: 10px;
      text-decoration: none;
      color: $primary;
      font-size: toRem(18);
      
    }
    a.page-numbers:hover {
      background-color: $secondary;
      color: $white;
    }
    span.current {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid $secondary;
      background-color: $secondary;
      border-radius: 8px;
      text-decoration: none;
      color: $white;
      font-size: toRem(18);
    }
  }
  @include media-breakpoint-up(lg) { 
    margin-bottom: 96px;
  }
}


.show-more-products {
  background-color: $gray-300;
  border-radius: 8px;
  padding: 10px 0;
  text-align: center;
  margin-top: 24px;
  cursor: pointer;
  transition: $transition-base;
  font-size: toRem(18);
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    i {
      margin-left: 10px;
    }
  }
  &:hover {
    background-color: $gray-100;
  }
}