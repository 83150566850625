@mixin btn {
  font-weight: 500; 
  font-size: toRem(16);
  text-decoration: none;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  transition: all .2s ease-in-out;
  align-items: center;
  -webkit-appearance: none;
  border: 1px solid transparent;
  padding: 9px 20px; 
  min-width: 130px;
  letter-spacing: 0.15px;
  box-shadow: none !important;
  border-radius: 30px;
  [class^="icon-"], [class*=" icon-"] {
    line-height: 1;
  }
  &.btn-right-icon {
    [class^="icon-"], [class*=" icon-"] {
      margin-left: 8px;
      transition: transform 0.3s;

    }
  }
  &.btn-left-icon {
    [class^="icon-"], [class*=" icon-"] {
      margin-right: 8px;
      transition: transform 0.3s;

    }
  }
  &:focus {
    outline: none;
  }
  &:hover {
    //transform: scale(1.1);
    text-decoration: none;
    &.btn-right-icon {
      [class^="icon-"], [class*=" icon-"] {
        transform: translateX(5px);

      }
    }
    &.btn-left-icon {
      [class^="icon-"], [class*=" icon-"] {
        transform: translateX(-5px);
  
      }
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  
}

@mixin btn-primary {
  color: $white;
  background: $primary;
  &:hover {
    background-color: $secondary;
  }
}
@mixin btn-primary-light {
  color: $white;
  background:#1D1D1B;
  &:hover {
    background-color: $secondary;
  }
}
@mixin btn-primary-border {
  color: $primary;
  background: transparent;
  border-color: $primary;
  &:hover {
    background-color: $green;
    border-color: $green;
    color: $white;
  }
}
@mixin btn-secondary {
  color: $secondary;
  background: $white;
  &:hover {
    background-color: darken($color: $white, $amount: 20);
  }
}
@mixin btn-secondary-border {
  color: $secondary;
  border-color: $secondary;
  background-color: $white;
  &:hover {
    background-color: #131414;
    border-color: #131414;
    color: $white;
  }
}

@mixin btn-white {
  color: $primary;
  background: $white;
  &:hover {
    background-color: $secondary;
    color: $white;
  }
}





.btn {
  @include btn;
  &.btn-primary {
    @include btn-primary;
  } 
  &.btn-primary-light{
    @include btn-primary-light;
  }
  &.btn-secondary {
    @include btn-secondary;
  } 

  &.btn-secondary-border {
    @include btn-secondary-border;
  } 
  &.btn-primary-border {
    @include btn-primary-border;
  }
  &.btn-white {
    @include btn-white;
  } 
  &.btn-small {
    font-size: toRem(14);
    line-height: toRem(18);
    padding: 7px 12px;
  }
}
