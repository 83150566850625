@for $i from 0 through 10 {
  .mb-#{$i*10} {
    margin-bottom: #{$i*10}px !important;
  }
  .mt-#{$i*10} {
    margin-top: #{$i*10}px !important;
  }
  .mr-#{$i*10} {
    margin-right: #{$i*10}px !important;
  }
  .ml-#{$i*10} {
    margin-left: #{$i*10}px !important;
  }
  .pb-#{$i*10} {
    padding-bottom: #{$i*10}px !important;
  }
  .pt-#{$i*10} {
    padding-top: #{$i*10}px !important;
  }
  .pl-#{$i*10} {
    padding-left: #{$i*10}px !important;
  }
  .pr-#{$i*10} {
    padding-right: #{$i*10}px !important;
  }
}

