
.gdpr_lightbox {
  #moove_gdpr_cookie_modal {
    .moove-gdpr-modal-content {
      .moove-gdpr-modal-close {
        display:none;
      }
      .moove-gdpr-modal-right-content {
        border-radius: 5px !important;
        .main-modal-content {
          @media (max-width: 767px) {
            max-height: calc(80vh - 244px) !important;
          }
        }
      }

      .moove-gdpr-modal-footer-content {
        flex-direction: row-reverse;
        
        @media (max-width: 767px) {
          height: auto;
          &::before {
            display: none;
          }
        }
        .moove-gdpr-branding-cnt {
          display: none;
        }
        .moove-gdpr-button-holder {
          flex-direction: row-reverse;
          margin: 0 !important;
          max-width: 100% !important;
          width: 100% !important;
          @media (max-width: 767px) {
            flex-direction: column;
            width: 100% !important;

            button.mgbutton {
              width: 100% !important;
              padding: 10px 15px !important;
            }
          }
          button.mgbutton {
            padding: 10px 37px !important;
            
            background-color: #666666 !important;
            border-color: #666666 !important;
            margin-bottom: 10px !important;
            &.moove-gdpr-modal-allow-all {
              background-color:#191817 !important;
              border-color: #191817 !important;
            }
            &.moove-gdpr-modal-reject-all {
              order: 3;
            }
            &:hover {
              color: $white !important;
            }
            @media (min-width: 1200px) {
              min-width: 260px;
            }
          }
        }
      }  
    }
    
    
  }  
}


#moove_gdpr_cookie_info_bar {
  visibility: hidden;
}