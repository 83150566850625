
.main-header {
  background-color: $white;
  transition: top 0.3s;
  .navbar-brand {
    .light {
      display: none;
    }
  }
  &.main-header-homepage {
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 10;
    &::before {
      content: '';
      background-color: $white;
      opacity: 0;
      border-radius: 0px 0px 30px 30px;
      @include absolutefull;
      transition: opacity 0.3s;
      z-index: -1;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    }
    &::after {
      content: '';
      background-color: $primary;
      opacity: 0;
      @include absolutefull;
      transition: opacity 0.3s;
      z-index: -1;
    }
    .navbar-brand {
      .light {
        display: block;
      }
      .dark {
        display: none;
      }
    }
    &.scroll, &.main-header-blog  {
      .navbar-brand {
        .light {
          display: none;
        }
        .dark {
          display: block;
        }
      }
      &::before {
        opacity: 1;
      }
      
    }
  }
}

