.middle-main-header {
  padding: 16px 0;
  .middle-header-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .navbar-brand {
    max-width: 155px;
  }
  .right-side-menu {
    .btn-mobile {
      display: flex;
      flex-direction: column;
      font-size: toRem(10);
      align-items: center;
      margin-left: 20px;
      text-decoration: none;
      position: relative;
      color: $white;
      i {
        font-size: toRem(24);
        margin-bottom: 8px;
      }
      &.is-active{
        color: $white;
      }
    }
  }
  @include media-breakpoint-up(lg) { 
    padding: 29px 0;
    .middle-header-wrap {
      justify-content: space-between;
      padding-top: 0;
      padding-bottom: 0;
    }
    .navbar-brand {
      max-width: 200px;
    }
    .right-side-menu {
      display: none;
      
    }
    .navbar {
      padding: 0;
    }
    .nav-item {
      padding: 0 16px;
      display: flex;
      align-items: center;
      position: relative;
      .show-submenu {
        margin-left: 6px;
        font-size: toRem(14);
        &::after {
          content: '\e917'; 
          font-family: 'icomoon';
          color: $white;
          transition: $transition-base;
          display: block;
        }
        &.active {
          &::after {
            content: '\e918';
          }
        }
      }
      &.has-children:hover {
        .sub-menu {
          display: block;
        }
      }
    }
    .sub-menu {
      position: absolute;
      top: 100%;
      left: 0;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background-color: $white;
      padding: 10px 12px;
      list-style: none;
      display: none;
      .nav-item {
        padding: 6px 0;
      }
      .nav-link {
        color: $primary;
        white-space: nowrap;
        padding: 0;
        &.energia {
          padding-left: 36px;
          background-image: url('./../assets/img/leaf.png');
          background-size: 16px;
          background-repeat: no-repeat;
          background-position: 10px 0;
        }
        &.energetika {
          padding-left: 36px;
          background-image: url('./../assets/img/union.png');
          background-size: 16px;
          background-repeat: no-repeat;
          background-position: 10px 0;
          &:hover {
            color: $orange !important;
          }
        }
      }

    }
    .nav-link {
      font-size: toRem(14);
      line-height: toRem(20);
      letter-spacing: 0.25px;
      color: $white;
      padding: 10px 0;
      &.btn {
        font-size: toRem(14);
        min-width: 0;
        padding: 9px 25px;
      }
      &.contact {
        padding-left: 45px;
        position: relative;
        &::before {
          content: '\e902';
          font-family: "icomoon" !important;
          position: absolute;
          left: 21px;
          font-size: toRem(16);
        }
      }
      &:hover:not(.btn) {
        color: $primary-hover;
        //font-weight: 500;
      }
    }
    .active {
      .nav-link {
        color: $primary-hover;
        //font-weight: 500;
        &.btn-primary {
          color: $white;
          background-color: $secondary;
        }
      }
    }
  }
}


.main-header-homepage.scroll, .main-header-blog {
  .right-side-menu {
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: $primary;
    }
    .btn-mobile {
      color: $primary;
      &.is-active{
        color: $primary;
      }
    }
  }
  @include media-breakpoint-up(lg) { 
    .nav-item {
      .show-submenu {
        &::after {
          color: $gray-800;
        }
      }
    }
    .nav-link:not(.btn) {
      color: $gray-800;
      &:hover {
        color: $primary-hover;
        //font-weight: 500;
      }
      
    }
    .active {
      .nav-link:not(.btn) {
        color: $primary-hover;
        //font-weight: 500;
      }
    }
  }
  
}

.main-header, .main-header-homepage.scroll, .main-header-homepage.main-header-blog {
  &.menu-show {
    //background-color: $primary;
    &::after {
      border-radius: 0;
      background-color: $primary;
      opacity: 1;
    }
    .navbar-brand {
      .light {
        display: block;
      }
      .dark {
        display: none;
      }
    }
    .right-side-menu {
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background-color: $secondary;
      }
      .btn-mobile {
        color: $secondary;
        &.is-active{
          color: $secondary;
        }
      }
    }
  }
}