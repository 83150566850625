html {
  //font-size: 62.5%;
}

body {
  overflow-x: hidden;
  margin: 0;
  position: relative;
  &.o-hidden {
    overflow: hidden;
  }
}

img {
  height: auto;
  max-width: 100%;
}



abbr[title], abbr[data-original-title] {
  text-decoration: none;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.bg-warning-light {
  background-color: $warning-light;
}

iframe {
  max-width: 100%;
}

hr {
  border-color: $gray-300;
  opacity: 1;
}
