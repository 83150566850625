.text-page {
  min-height: 50vh;
  z-index: 1;
  position: relative;
  background-color: $white;
  &.text-page-single {
    padding-bottom: 48px;
    padding-top: 64px;
  }
}

.single-filter-wrap {
  margin-bottom: 48px;
  .form-select {
    border-radius: 30px;
  }
  @include media-breakpoint-up(lg) { 
    display: flex;
    justify-content: space-between;
  }
}
.news-category-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  .category-item {
    font-size: toRem(14);
    padding: 10px 12px;
    border: 1px solid #C8C8C8;
    border-radius: 30px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-decoration: none;
    transition: $transition-base;
    &:hover, &.active {
      background-color: $secondary;
      color: $white;
    }
    @include media-breakpoint-up(md) { 
      margin-right: 16px;
    }
  }
  @include media-breakpoint-up(lg) { 
    margin-bottom: 0;
  }
}

.error-page {
  padding-top: 148px;
  padding-bottom: 48px;
  .image-wrap {
    text-align: center;
    margin-bottom: 48px;
  }
  h1 {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 32px;
    font-size: toRem(18);
  }
}

.text-page-single {
  h2,h3,h4 {
    margin-bottom: 24px;
  }
  img {
    border-radius: 8px;
  }
  p {
    &.perex {
      font-family: 'Poppins';
      font-size: toRem(20);
      line-height: 30px;
      font-weight: 500;
      margin-bottom: 48px;
    }
  }
  figure.wp-block-image {
    margin: 48px 0;
    figcaption {
      margin-top: 8px;
    }
  }
  .wp-block-column {
    figure.wp-block-image {
      margin: 0;
    }
  }
  figcaption.wp-element-caption {
    font-size: toRem(12);
    text-align: center;
  }
  @include media-breakpoint-up(lg) { 
    .wp-block-column {
      figure.wp-block-image {
        margin: 48px 0;
      }
    }
    p {
      &.perex {
        font-size: toRem(24);
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 64px;
      }
    }
  }
}

.block-references-section-wrap {
  padding: 64px 0 40px;
}

/* .wp-block-columns {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  margin-bottom: 24px;
  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
} */

/* @include media-breakpoint-up(md) { 
  .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
    flex-basis: 0;
    flex-grow: 1;
  }
}

@include media-breakpoint-down(md) { 
  .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
    flex-basis: 100%!important;
  }
}
 */

figure.wp-block-gallery.has-nested-images {
  align-items: normal;
}
.wp-block-gallery.has-nested-images figure.wp-block-image {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  max-width: 100%;
  position: relative;
  &:not(#individual-image) {
    margin: 0;
    width: calc(50% - var(--wp--style--unstable-gallery-gap, 16px)/2);
  }
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) {
  align-self: inherit;
}
@media (min-width: 600px) {
  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image) {
    width: calc(33.33% - var(--wp--style--unstable-gallery-gap, 16px)*.66667);
  }
  .wp-block-gallery.has-nested-images.columns-3 figure.wp-block-image:not(#individual-image) {
    width: calc(33.33% - var(--wp--style--unstable-gallery-gap, 16px)*.66667);
  }
}
.wp-block-image img {
  height: auto;
    max-width: 100%;
    vertical-align: bottom;
}
.wp-block-image img, .wp-block-image.has-custom-border img {
  box-sizing: border-box;
}
.wp-block-gallery.has-nested-images figure.wp-block-image img {
  display: block;
    height: auto;
    max-width: 100%!important;
    width: auto;
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) a, .wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) img {
  flex: 1 0 0%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

body .is-layout-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}